import React from "react";
import ReactTooltip from "react-tooltip";
import "./tooltip.scss";

const Tooltip = ({ tooltipText }) => {
  return (
    <div className="tooltip-icon" data-tip={tooltipText}>
      ? <ReactTooltip backgroundColor="#c4c4c4" textColor="black" />
    </div>
  );
};

export default Tooltip;
