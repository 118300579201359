import React, { useEffect, useState } from "react";
import "./input-field.scss";

const InputField = ({
  placeholderText,
  fieldName,
  fieldValue,
  handleValueChanged,
  alignLeft,
  isReadOnly,
  fieldSuffix,
  fieldType = "number",
  validationText = "Please enter a valid amount",
}) => {
  const [showValidationError, setShowValidationError] = useState("");
  const [wasTouched, setWasTouched] = useState(false);

  useEffect(() => {
    if (wasTouched) {
      setShowValidationError(!validateField());
    }
  }, [fieldValue]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFieldValueChanged = (newValue) => {
    if (showValidationError) {
      setShowValidationError(!validateField());
    }
    handleValueChanged(fieldName, newValue);
  };

  const validateField = () => {
    if (fieldValue.length === 0) {
      return false;
    }
    switch (fieldType) {
      case "number":
        return !isNaN(fieldValue);
      case "email":
        if (
          !fieldValue.includes("@") ||
          !fieldValue.includes(".") ||
          !fieldValue.length > 4 ||
          fieldValue.indexOf(".") === fieldValue.length - 1
        ) {
          return false;
        }
    }
    return true;
  };

  return (
    <div className="input-field-container">
      <div className="input-field-values">
        <input
          placeholder={placeholderText}
          type="text"
          className="input-field"
          style={{
            textAlign: alignLeft ? "left" : "right",
            marginRight: !fieldSuffix ? "-20px" : "0px",
          }}
          value={fieldValue}
          onChange={(newValue) =>
            handleFieldValueChanged(newValue.target.value)
          }
          readOnly={isReadOnly}
          onFocus={() => setWasTouched(true)}
          onBlur={() => setShowValidationError(!validateField())}
        />
        <div className="input-field-suffix-container">{fieldSuffix ?? ""}</div>
      </div>
      {showValidationError && (
        <div className="input-field-validation-text">{validationText}</div>
      )}
    </div>
  );
};

export default InputField;
