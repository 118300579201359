import React from "react";
import Checkbox from "../checkbox/checkbox";
import InputField from "../input-field/input-field";
import RoundedButton from "../rounded-button/rounded-button";
import "./contact-details.scss";

const ContactDetails = ({
  fieldValues,
  handleValueChanged,
  handleCalculateClicked,
  validationPassed,
}) => {
  return (
    <div className="contact-details-container">
      <div className="contact-details-left-content">
        <div className="contact-details-name-container">
          <InputField
            alignLeft={true}
            placeholderText="Name"
            fieldName="name"
            fieldValue={fieldValues["name"]}
            handleValueChanged={handleValueChanged}
            fieldType="name"
            validationText="Please enter a valid name"
          />
          <InputField
            alignLeft={true}
            placeholderText="Surname"
            fieldName="surname"
            fieldValue={fieldValues["surname"]}
            handleValueChanged={handleValueChanged}
            fieldType="name"
            validationText="Please enter a valid suranme"
          />
        </div>
        <InputField
          alignLeft={true}
          placeholderText="Your Email"
          fieldName="emailAddress"
          fieldValue={fieldValues["emailAddress"]}
          handleValueChanged={handleValueChanged}
          fieldType="email"
          validationText="Please enter a valid email address"
        />
        <div className="contact-details-disclaimer-text">
          Yoyo is committed to protecting and respecting your privacy, and we’ll
          only use your personal information to administer your account and to
          provide the products and services you requested from us. From time to
          time, we would like to contact you about our products and services, as
          well as other content that may be of interest to you. If you consent
          to us contacting you for this purpose, please tick below.
        </div>
        <Checkbox
          checkboxText="I agree to receive other communications from Yoyo."
          handleValueChanged={handleValueChanged}
        />
        <div className="contact-details-disclaimer-text">
          You can unsubscribe from these communications at any time. For more
          information on how to unsubscribe, our privacy practices, and how we
          are committed to protecting and respecting your privacy, please review
          our
          <a
            href="https://yoyogroup.com/privacy-policy/"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy.
          </a>
          <br />
          <br />
          By clicking submit below, you consent to allow Yoyo to store and
          process the personal information submitted above to provide you the
          content requested.
          <br />
          <br />
          <br />
          <RoundedButton
            buttonText="Calculate"
            handleClicked={() => handleCalculateClicked()}
            isEnabled={validationPassed}
          />
        </div>
      </div>
    </div>
  );
};

export default ContactDetails;
