import React from "react";
import InputField from "../input-field/input-field";
import "./input-row.scss";
import Tooltip from "../tooltip/tooltip";

const InputRow = ({
  fieldName,
  fieldText,
  fieldValue,
  handleValueChanged,
  tooltipText,
  fieldPrefix,
  fieldSuffix,
}) => {
  return (
    <div className="input-row-container">
      <div className="input-row-text">
        {fieldText}
        {tooltipText && <Tooltip tooltipText={tooltipText} />}
      </div>

      <InputField
        fieldValue={fieldValue}
        fieldName={fieldName}
        handleValueChanged={handleValueChanged}
        fieldPrefix={fieldPrefix}
        fieldSuffix={fieldSuffix}
      />
    </div>
  );
};

export default InputRow;
