import React, { useState } from "react";
import Divider from "../divider/divider";
import "./accordion.scss";
import { BsChevronUp } from "react-icons/bs";

const Accordion = ({
  children,
  headingText,
  subheadingText,
  collapsed
}) => {
  const [isCollapsed, setIsCollapsed] = useState(collapsed);

  const handleControlButtonClicked = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className="accordion-container">
      <div className="accordion-header">
        <span className="accordion-header-text">{headingText}</span>
        <div
          className="accordion-control-button"
          onClick={() => handleControlButtonClicked()}
        >
          {isCollapsed && (
            <div className="accordion-control-button-indicator">+</div>
          )}
          {!isCollapsed && (
            <div className="accordion-control-button-indicator-collapsed">
              <BsChevronUp />
            </div>
          )}
        </div>
      </div>
      {!isCollapsed && (
        <div className="accordion-child-content">
          {subheadingText && (
            <div className="accordion-subheading-text">{subheadingText}</div>
          )}
          {children}
        </div>
      )}
      <Divider />
    </div>
  );
};

export default Accordion;
