export const yoyoTransactionsPercentage = 1

export const licenseFee = (currency) => {
  let fee
  switch (currency) {
  case '£':
    fee = 75.00
    break
  case 'R':
    fee = 75.00
    break
  case '€':
    fee = 75.00
    break
  default:
    fee = 0
    break
  }
  return fee
}

export const customerDataMatch = (currency) => {
  let fee
  switch (currency) {
  case '£':
    fee = 0.05
    break
  case 'R':
    fee = 0.05
    break
  case '€':
    fee = 0.05
    break
  default:
    fee = 0
    break
  }
  return fee
}
