import React from "react";
import InputField from "../input-field/input-field";
import "./impact-row.scss";

const ImpactRow = ({
  headingText,
  isBold,
  isHeaderRow,
  perOutletValue,
  allOutletsValue,
}) => {
  return (
    <div
      className="impact-row"
      style={{ fontWeight: isBold ? "bold" : "regular" }}
    >
      <div
        className="impact-row-heading"
        style={{ fontSize: isHeaderRow ? "36px" : "24px" }}
      >
        {isHeaderRow && <div className="impact-row-header-text">Impact</div>}
        <span className="impact-row-text">{!isHeaderRow && headingText}</span>
      </div>
      <div className="impact-row-values-container">
        <div className="impact-row-input-field">
          {!isHeaderRow && (
            <InputField fieldValue={perOutletValue} isReadOnly={true} />
          )}
        </div>
        <div className="impact-row-spacer"></div>
        <div className="impact-row-input-field">
          {!isHeaderRow && (
            <InputField fieldValue={allOutletsValue} isReadOnly={true} />
          )}
        </div>
      </div>
    </div>
  );
};

export default ImpactRow;
