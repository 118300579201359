import React from "react";
import "./currency-selector.scss";

const CurrencySelector = ({
  currencyOptions,
  selectedCurrency,
  setSelectedCurrency,
}) => {
  return (
    <div className="currency-selector-container">
      <small>Choose your currency</small>
      {currencyOptions.map((currencyOption, i) => {
        return (
          <div
            key={i}
            className="currency-option"
            style={{
              textDecoration:
                selectedCurrency === currencyOption ? "underline" : "none",
              color: selectedCurrency === currencyOption ? "#3D5AFE" : "black",
            }}
            onClick={() => setSelectedCurrency(currencyOption)}
          >
            {currencyOption}
          </div>
        );
      })}
    </div>
  );
};

export default CurrencySelector;
