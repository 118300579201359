import React, { useEffect, useState } from "react";
import Accordion from "../../components/accordion/accordion";
import ContactDetails from "../../components/contact-details/contact-details";
import InputRow from "../../components/input-row/input-row";
import {
  netImpact,
  regularCustomers,
  transactionFrequencyUplift,
  yoyoTransactions,
  totalRevenue,
  revenueUplift,
  processingAndLoyalty,
  estimatedCostOfProgramme,
  returnOnInvestment,
} from "../../libs/impact-calculations";
import { useHistory } from "react-router-dom";
import "./calculations.scss";
import CurrencySelector from "../../components/currency-selector/currency-selector";
import Header from "../../components/header/header";

const Calculations = ({
  setSummaryValues,
  currencyOptions,
  selectedCurrency,
  setSelectedCurrency,
}) => {
  const [fieldValues, setFieldValues] = useState({
    monthlyRevenuePerStore: 0,
    averageTransactionValue: 0,
    numberOfStores: 0,
    percentageOfLoyalCustomersWhoRedeemRewards: 60,
    valueOfRewards: 10,
    averageTransactionValueIncrease: 0,
    transactionFrequencyIncrease: 0,
    yoyoShareOfCheckout: 0,
    name: "",
    surname: "",
    emailAddress: "",
    canSendCommunication: true,
  });

  const [validationPassed, setValidationPassed] = useState(false);

  useEffect(() => {
    validateFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldValues]);

  const validateFields = () => {
    let allFieldsValid = checkNumberFields([
      fieldValues.monthlyRevenuePerStore,
      fieldValues.averageTransactionValue,
      fieldValues.numberOfStores,
      fieldValues.percentageOfLoyalCustomersWhoRedeemRewards,
      fieldValues.valueOfRewards,
      fieldValues.averageTransactionValueIncrease,
      fieldValues.transactionFrequencyIncrease,
      fieldValues.yoyoShareOfCheckout,
    ]);
    if (!fieldValues.name || !fieldValues.surname) {
      allFieldsValid = false;
    }
    if (
      !fieldValues.emailAddress.includes("@") ||
      !fieldValues.emailAddress.includes(".") ||
      !fieldValues.emailAddress.length > 4 ||
      fieldValues.emailAddress.indexOf(".") ===
        fieldValues.emailAddress.length - 1
    ) {
      allFieldsValid = false;
    }
    if (!fieldValues.canSendCommunication) {
      allFieldsValid = false;
    }
    setValidationPassed(allFieldsValid);
  };

  const parseNumberFields = () => {
    let newFieldValues = { ...fieldValues };
    newFieldValues.monthlyRevenuePerStore = parseFloat(
      newFieldValues.monthlyRevenuePerStore
    );
    newFieldValues.averageTransactionValue = parseFloat(
      newFieldValues.averageTransactionValue
    );
    newFieldValues.numberOfStores = parseFloat(newFieldValues.numberOfStores);
    newFieldValues.percentageOfLoyalCustomersWhoRedeemRewards = parseFloat(
      newFieldValues.percentageOfLoyalCustomersWhoRedeemRewards
    );
    newFieldValues.valueOfRewards = parseFloat(newFieldValues.valueOfRewards);
    newFieldValues.averageTransactionValueIncrease = parseFloat(
      newFieldValues.averageTransactionValueIncrease
    );
    newFieldValues.transactionFrequencyIncrease = parseFloat(
      newFieldValues.transactionFrequencyIncrease
    );
    newFieldValues.yoyoShareOfCheckout = parseFloat(
      newFieldValues.yoyoShareOfCheckout
    );

    newFieldValues.currency = selectedCurrency;

    return newFieldValues;
  };

  const checkNumberFields = (numberFields) => {
    for (let i = 0; i < numberFields.length; i++) {
      if (isNaN(numberFields[i]) || numberFields[i].length < 1) {
        return false;
      }
    }
    return true;
  };

  const handleCalculateClicked = () => {
    const parsedFieldValues = parseNumberFields(fieldValues);
    setSummaryValues({
      increasedTransactions: transactionFrequencyUplift(parsedFieldValues),
      yoyoTransactions: yoyoTransactions(parsedFieldValues),
      regularCustomers: regularCustomers(parsedFieldValues),
      totalRevenue: totalRevenue(parsedFieldValues),
      revenueUplift: revenueUplift(parsedFieldValues),
      processingAndLoyalty: processingAndLoyalty(parsedFieldValues),
      estimatedCostOfProgramme: estimatedCostOfProgramme(parsedFieldValues),
      netImpact: netImpact(parsedFieldValues),
      returnOnInvestment: returnOnInvestment(parsedFieldValues),
    });
    fetch(`${process.env.REACT_APP_API_URL}/contacts`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(parsedFieldValues)
      })
      .then(response => response.json())
      .then(data => console.log(data));
    history.push("/summary");
  };

  const history = useHistory();

  const handleValueChanged = (fieldName, newValue) => {
    let newFieldValues = { ...fieldValues };
    newFieldValues[fieldName] = newValue;
    setFieldValues(newFieldValues);
  };
  return (
    <div className="calculations-container">
      <div className="calculations-header">
        <Header />
      </div>
      <CurrencySelector
        currencyOptions={currencyOptions}
        selectedCurrency={selectedCurrency}
        setSelectedCurrency={setSelectedCurrency}
      />
      <Accordion headingText="Basic Information" collapsed={true}>
        <InputRow
          handleValueChanged={handleValueChanged}
          fieldName="monthlyRevenuePerStore"
          fieldText="Monthly Revenue Per Outlet"
          fieldValue={fieldValues["monthlyRevenuePerStore"]}
        />
        <InputRow
          handleValueChanged={handleValueChanged}
          fieldName="averageTransactionValue"
          fieldText="Average Transaction Value"
          fieldValue={fieldValues["averageTransactionValue"]}
        />
        <InputRow
          handleValueChanged={handleValueChanged}
          fieldName="numberOfStores"
          fieldText="Number of Outlets"
          fieldValue={fieldValues["numberOfStores"]}
        />
      </Accordion>
      <Accordion headingText="Reward Costs" collapsed={true}>
        <InputRow
          handleValueChanged={handleValueChanged}
          fieldName="percentageOfLoyalCustomersWhoRedeemRewards"
          fieldText="Percentage Of Loyal Customers Who Redeem Rewards"
          fieldValue={fieldValues["percentageOfLoyalCustomersWhoRedeemRewards"]}
          fieldSuffix="%"
        />
        <InputRow
          handleValueChanged={handleValueChanged}
          fieldName="valueOfRewards"
          fieldText="Value of Rewards (% of Spend)"
          fieldValue={fieldValues["valueOfRewards"]}
          fieldSuffix="%"
        />
      </Accordion>
      <Accordion
        headingText="Loyalty Uplift"
        subheadingText="After 12 months of using Yoyo, some clients have gained a 26% uplift in customer transaction frequency and a 24% increase in average transaction value."
        collapsed={false}
      >
        <InputRow
          handleValueChanged={handleValueChanged}
          fieldName="averageTransactionValueIncrease"
          fieldText="Average Transaction Value Increase"
          tooltipText="The percentage increase of your average customer 
          transaction value that Yoyo will deliver."
          fieldValue={fieldValues["averageTransactionValueIncrease"]}
          fieldSuffix="%"
        />
        <InputRow
          handleValueChanged={handleValueChanged}
          fieldName="transactionFrequencyIncrease"
          fieldText="Transaction Frequency Increase"
          tooltipText="The percentage increase in the number of customer visits
          that your Yoyo-powered loyalty programme will deliver."
          fieldValue={fieldValues["transactionFrequencyIncrease"]}
          fieldSuffix="%"
        />
        <InputRow
          handleValueChanged={handleValueChanged}
          fieldName="yoyoShareOfCheckout"
          fieldText="Loyalty Adoption"
          tooltipText="The percentage of your existing customer base who you 
        expect to engage in your Yoyo-powered loyalty programme."
          fieldValue={fieldValues["yoyoShareOfCheckout"]}
          fieldSuffix="%"
        />
      </Accordion>
      <ContactDetails
        fieldValues={fieldValues}
        handleValueChanged={handleValueChanged}
        handleCalculateClicked={handleCalculateClicked}
        validationPassed={validationPassed}
      />
    </div>
  );
};

export default Calculations;
