import React from "react";
import "./rounded-button.scss";

const RoundedButton = ({
  buttonText,
  extraPadding,
  handleClicked,
  isEnabled,
}) => {
  return (
    <div
      className="rounded-button"
      style={{
        paddingLeft: extraPadding ? "36px" : "18px",
        paddingRight: extraPadding ? "36px" : "18px",
        opacity: isEnabled ? 100 : 0.8,
        cursor: isEnabled ? "pointer" : "not-allowed",
      }}
      onClick={isEnabled ? () => handleClicked() : null}
    >
      {buttonText}
    </div>
  );
};

export default RoundedButton;
