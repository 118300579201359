import React from "react";
import "./header.scss";
import Logo from "./yoyopro-logo.png";

const Header = () => {
  return (
    <div className="header-container">
      <img
        alt="YoyoPro Calculator"
        border="0"
        className="yoyopro-logo"
        src={Logo}
        title="YoyoPro Calculator" />
    </div>
  );
};

export default Header;