import React from "react";
import "./value-card.scss";

const ValueCard = ({
  backgroundColour = "#3D5AFE",
  headingText,
  valueText,
  Icon
}) => {
  return (
    <div
      className="value-card-container"
      style={{ backgroundColor: backgroundColour }}
    >
      <span className="value-card-icon">
        <img src={Icon} />
      </span>
      <div>
        <span className="value-card-heading-text">{headingText}</span>
        <span className="value-card-value-text">{valueText}</span>
      </div>
    </div>
  );
};

export default ValueCard;
