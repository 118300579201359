import React, { useState } from "react";
import "./checkbox.scss";

const Checkbox = ({ checkboxText, handleValueChanged }) => {
  const [isChecked, setIsChecked] = useState(true);
  const handleCheckedClick = () => {
    handleValueChanged("canSendCommunication", !isChecked);
    setIsChecked(!isChecked);
  };

  return (
    <div className="checkbox-container">
      <div
        className="checkbox"
        style={{ backgroundColor: isChecked ? "#FCC02B" : "white" }}
        onClick={() => handleCheckedClick()}
      ></div>
      <div className="checkbox-text">{checkboxText}</div>
    </div>
  );
};

export default Checkbox;
