import React from "react";
import ImpactRow from "../../components/impact-row/impact-row";
import ValueCard from "../../components/value-card/value-card";
import RoundedButton from "../../components/rounded-button/rounded-button";
import { formatCurrency } from "../../libs/helpers";
import { useHistory } from "react-router-dom";
import "./summary.scss";
import Growth from "./growth@2x.png";
import ROI from "./spent-on-CRM@2x.png";

const Summary = ({ summaryValues, currencySymbol }) => {
  const history = useHistory();

  const handleGetStartedClicked = () => {
    history.push("/");
  };

  return (
    <div className="summary-container">
      <div className="summary-container-value-cards-row">
        <ValueCard
          headingText="Additional Revenue Generated From Loyalty"
          valueText={formatCurrency(
            summaryValues.revenueUplift.total,
            currencySymbol
          )}
          backgroundColour="rgb(61, 90, 254, 0.2)"
          Icon={Growth}
        />
        <ValueCard
          headingText="Return On Investment"
          valueText={`${summaryValues.returnOnInvestment.total.toLocaleString(
            undefined,
            {
              maximumFractionDigits: 2,
            }
          )}%`}
          backgroundColour="rgb(54, 214, 210, 0.2)"
          Icon={ROI}
        />
      </div>
      <div className="summary-content">
        <ImpactRow isHeaderRow={true} />
        <ImpactRow
          headingText="Increased Transactions"
          perOutletValue={summaryValues.increasedTransactions.store.toLocaleString(
            undefined,
            {
              maximumFractionDigits: 0,
            }
          )}
          allOutletsValue={summaryValues.increasedTransactions.total.toLocaleString(
            undefined,
            {
              maximumFractionDigits: 0,
            }
          )}
        />
        <ImpactRow
          headingText="Yoyo Transactions"
          perOutletValue={formatCurrency(
            summaryValues.yoyoTransactions.store,
            currencySymbol
          )}
          allOutletsValue={formatCurrency(
            summaryValues.yoyoTransactions.total,
            currencySymbol
          )}
        />
        <ImpactRow
          headingText="Regular Customers"
          perOutletValue={formatCurrency(
            summaryValues.regularCustomers.store,
            currencySymbol
          )}
          allOutletsValue={formatCurrency(
            summaryValues.regularCustomers.total,
            currencySymbol
          )}
        />
        <ImpactRow
          headingText="Total Revenue"
          perOutletValue={formatCurrency(
            summaryValues.totalRevenue.store,
            currencySymbol
          )}
          allOutletsValue={formatCurrency(
            summaryValues.totalRevenue.total,
            currencySymbol
          )}
        />
        <ImpactRow
          headingText="Revenue Uplift"
          perOutletValue={formatCurrency(
            summaryValues.revenueUplift.store,
            currencySymbol
          )}
          allOutletsValue={formatCurrency(
            summaryValues.revenueUplift.total,
            currencySymbol
          )}
        />
        <ImpactRow
          headingText="Estimated Cost of Programme"
          perOutletValue={formatCurrency(
            summaryValues.estimatedCostOfProgramme.store,
            currencySymbol
          )}
          allOutletsValue={formatCurrency(
            summaryValues.estimatedCostOfProgramme.total,
            currencySymbol
          )}
        />
        <ImpactRow
          headingText="Net Impact"
          perOutletValue={formatCurrency(
            summaryValues.netImpact.store,
            currencySymbol
          )}
          allOutletsValue={formatCurrency(
            summaryValues.netImpact.total,
            currencySymbol
          )}
          isBold={true}
        />
      </div>
      <div className="summary-get-started-button">
        <RoundedButton
          buttonText="Start Over"
          extraPadding={true}
          handleClicked={handleGetStartedClicked}
          isEnabled={true}
        />
      </div>
      <div className="summary-disclaimer-text">
        Yoyo is unable to warrant the accuracy of the calculations on this
        website. Yoyo therefore cannot be held responsible for any direct or
        indirect loss or damages of any nature that you may suffer should you
        rely on the use of this website and the calculator provided herein.
        While every effort is made to ensure that the calculations are correct,
        you hereby acknowledge that the calculator is intended solely to provide
        an indication and that the commercials are subject to change. You
        accordingly undertake responsibility to confirm the actual commercials
        with your Yoyo professional and acknowledge that Yoyo will not accept
        any liability as aforementioned for reliance placed on figures in the
        absence of confirmation.
      </div>
    </div>
  );
};

export default Summary;
