import "./App.css";
import Calculations from "./pages/calculations/calculations";
import Summary from "./pages/summary/summary";
import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

function App() {
  const [summaryValues, setSummaryValues] = useState();
  const [selectedCurrency, setSelectedCurrency] = useState("£");
  // const currencyOptions = ["£", "€", "R"];
  const currencyOptions = ["£"];

  return (
    <Router>
      <div className="App">
        <Switch>
          <Route path="/summary">
            <Summary
              summaryValues={summaryValues}
              currencySymbol={selectedCurrency}
            />
          </Route>
          <Route path="/">
            <Calculations
              summaryValues={summaryValues}
              setSummaryValues={setSummaryValues}
              currencyOptions={currencyOptions}
              selectedCurrency={selectedCurrency}
              setSelectedCurrency={setSelectedCurrency}
            />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
