import {
  percentToDecimal,
  shareOfCheckout,
  transactionsPerMonth,
  upliftTransactionFrequency,
  upliftTransactionValue,
} from "./minor-calculations";

import {
  customerDataMatch,
  licenseFee,
  yoyoTransactionsPercentage,
} from "./inputs";

/**
 * @param {number} monthlyRevenuePerStore
 * @param {number} averageTransactionValue
 * @param {number} transactionFrequencyIncrease
 * @param {number} numberOfStores
 * @returns {{total: number, store: number}}
 */
export const transactionFrequencyUplift = ({
  monthlyRevenuePerStore,
  averageTransactionValue,
  transactionFrequencyIncrease,
  numberOfStores,
}) => {
  const store = upliftTransactionFrequency({
    monthlyRevenuePerStore,
    averageTransactionValue,
    transactionFrequencyIncrease,
  });
  const total = store * numberOfStores;
  return { total, store };
};

/**
 * @param {number}  averageTransactionValue
 * @param {number}  monthlyRevenuePerStore
 * @param {number}  yoyoShareOfCheckout
 * @param {number} averageTransactionValueIncrease
 * @param {number} transactionFrequencyIncrease
 * @param {number}  numberOfStores
 * @returns {{total: number, store: number}}
 */
export const yoyoTransactions = ({
  averageTransactionValue,
  monthlyRevenuePerStore,
  yoyoShareOfCheckout,
  averageTransactionValueIncrease,
  transactionFrequencyIncrease,
  numberOfStores,
}) => {
  const store =
    (averageTransactionValue +
      upliftTransactionValue({
        averageTransactionValue,
        averageTransactionValueIncrease,
      })) *
    shareOfCheckout({
      monthlyRevenuePerStore,
      averageTransactionValue,
      transactionFrequencyIncrease,
      yoyoShareOfCheckout,
    });
  const total = store * numberOfStores;
  return { total, store };
};

/**
 * @param {number} monthlyRevenuePerStore
 * @param {number} averageTransactionValue
 * @param {number} transactionFrequencyIncrease
 * @param {number} yoyoShareOfCheckout
 * @param {number} numberOfStores
 * @returns {{total: number, store: number}}
 */
export const regularCustomers = ({
  monthlyRevenuePerStore,
  averageTransactionValue,
  transactionFrequencyIncrease,
  yoyoShareOfCheckout,
  numberOfStores,
}) => {
  const store =
    (transactionsPerMonth({ monthlyRevenuePerStore, averageTransactionValue }) -
      shareOfCheckout({
        monthlyRevenuePerStore,
        averageTransactionValue,
        transactionFrequencyIncrease,
        yoyoShareOfCheckout,
      })) *
    averageTransactionValue;
  const total = store * numberOfStores;
  return { total, store };
};

/**
 * @param {number} monthlyRevenuePerStore
 * @param {number} averageTransactionValue
 * @param {number} averageTransactionValueIncrease
 * @param {number} yoyoShareOfCheckout
 * @param {number} transactionFrequencyIncrease
 * @param {number} numberOfStores
 * @returns {{total: number, store: number}}
 */
export const totalRevenue = ({
  monthlyRevenuePerStore,
  averageTransactionValue,
  averageTransactionValueIncrease,
  yoyoShareOfCheckout,
  transactionFrequencyIncrease,
  numberOfStores,
}) => {
  const store =
    regularCustomers({
      monthlyRevenuePerStore,
      averageTransactionValue,
      transactionFrequencyIncrease,
      yoyoShareOfCheckout,
      numberOfStores,
    }).store +
    yoyoTransactions({
      averageTransactionValue,
      monthlyRevenuePerStore,
      yoyoShareOfCheckout,
      averageTransactionValueIncrease,
      transactionFrequencyIncrease,
      numberOfStores,
    }).store;
  const total = store * numberOfStores;
  return { total, store };
};

/**
 * @param {number} monthlyRevenuePerStore
 * @param {number} averageTransactionValue
 * @param {number} averageTransactionValueIncrease
 * @param {number} yoyoShareOfCheckout
 * @param {number} transactionFrequencyIncrease
 * @param {number} numberOfStores
 * @returns {{total: number, store: number}}
 */
export const revenueUplift = ({
  monthlyRevenuePerStore,
  averageTransactionValue,
  averageTransactionValueIncrease,
  yoyoShareOfCheckout,
  transactionFrequencyIncrease,
  numberOfStores,
}) => {
  const store =
    totalRevenue({
      monthlyRevenuePerStore,
      averageTransactionValue,
      averageTransactionValueIncrease,
      yoyoShareOfCheckout,
      transactionFrequencyIncrease,
      numberOfStores,
    }).store -
    regularCustomers({
      monthlyRevenuePerStore,
      averageTransactionValue,
      transactionFrequencyIncrease,
      yoyoShareOfCheckout,
      numberOfStores,
    }).store;

  const total = store * numberOfStores;
  return { store, total };
};

/**
 * @param {number} monthlyRevenuePerStore
 * @param {number} averageTransactionValue
 * @param {number} averageTransactionValueIncrease
 * @param {number} yoyoShareOfCheckout
 * @param {number} transactionFrequencyIncrease
 * @param {number} numberOfStores
 * @param {string} currency
 * @returns {{total: number, store: number}}
 */
export const processingAndLoyalty = ({
  monthlyRevenuePerStore,
  averageTransactionValue,
  averageTransactionValueIncrease,
  transactionFrequencyIncrease,
  yoyoShareOfCheckout,
  currency,
  numberOfStores,
}) => {
  const share = shareOfCheckout({
    monthlyRevenuePerStore,
    averageTransactionValue,
    transactionFrequencyIncrease,
    yoyoShareOfCheckout,
  });
  const store =
    share * customerDataMatch(currency) +
    share *
      percentToDecimal(yoyoTransactionsPercentage) *
      (averageTransactionValue +
        upliftTransactionValue({
          averageTransactionValue,
          averageTransactionValueIncrease,
        }));
  const total = store * numberOfStores;

  return { total, store };
};

/**
 * @param {number} numberOfStores
 * @param {string} currency
 * @returns {{total: number, store: number}}
 */
export const getLicenseFee = ({ currency, numberOfStores }) => {
  const store = licenseFee(currency);
  const total = store * numberOfStores;

  return { total, store };
};

/**
 * @param {number} monthlyRevenuePerStore
 * @param {number} averageTransactionValue
 * @param {number} averageTransactionValueIncrease
 * @param {number} yoyoShareOfCheckout
 * @param {number} transactionFrequencyIncrease
 * @param {number} percentageOfLoyalCustomersWhoRedeemRewards
 * @param {number} valueOfRewards
 * @param {number} numberOfStores
 * @returns {{total: number, store: number}}
 */
export const rewardsToLoyalCustomers = ({
  averageTransactionValue,
  monthlyRevenuePerStore,
  yoyoShareOfCheckout,
  averageTransactionValueIncrease,
  transactionFrequencyIncrease,
  numberOfStores,
  percentageOfLoyalCustomersWhoRedeemRewards,
  valueOfRewards,
}) => {
  const store =
    yoyoTransactions({
      averageTransactionValue,
      monthlyRevenuePerStore,
      yoyoShareOfCheckout,
      averageTransactionValueIncrease,
      transactionFrequencyIncrease,
      numberOfStores,
    }).store *
    percentToDecimal(percentageOfLoyalCustomersWhoRedeemRewards) *
    percentToDecimal(valueOfRewards);
  const total = store * numberOfStores;

  return { total, store };
};

/**
 * @param {number} monthlyRevenuePerStore
 * @param {number} averageTransactionValue
 * @param {number} averageTransactionValueIncrease
 * @param {number} yoyoShareOfCheckout
 * @param {number} transactionFrequencyIncrease
 * @param {number} percentageOfLoyalCustomersWhoRedeemRewards
 * @param {number} valueOfRewards
 * @param {number} numberOfStores
 * @param {string} currency
 * @returns {{total: number, store: number}}
 */
export const estimatedCostOfProgramme = ({
  averageTransactionValue,
  monthlyRevenuePerStore,
  yoyoShareOfCheckout,
  averageTransactionValueIncrease,
  transactionFrequencyIncrease,
  numberOfStores,
  percentageOfLoyalCustomersWhoRedeemRewards,
  valueOfRewards,
  currency,
}) => {
  const store =
    rewardsToLoyalCustomers({
      averageTransactionValue,
      monthlyRevenuePerStore,
      yoyoShareOfCheckout,
      averageTransactionValueIncrease,
      transactionFrequencyIncrease,
      numberOfStores,
      percentageOfLoyalCustomersWhoRedeemRewards,
      valueOfRewards,
    }).store +
    getLicenseFee({ currency, numberOfStores }).store +
    processingAndLoyalty({
      monthlyRevenuePerStore,
      averageTransactionValue,
      averageTransactionValueIncrease,
      transactionFrequencyIncrease,
      yoyoShareOfCheckout,
      currency,
      numberOfStores,
    }).store;

  const total = store * numberOfStores;

  return { total, store };
};

/**
 * @param {number} monthlyRevenuePerStore
 * @param {number} averageTransactionValue
 * @param {number} averageTransactionValueIncrease
 * @param {number} yoyoShareOfCheckout
 * @param {number} transactionFrequencyIncrease
 * @param {number} numberOfStores
 * @param {number} percentageOfLoyalCustomersWhoRedeemRewards
 * @param {number} valueOfRewards
 * @param {string} currency
 * @returns {{total: number, store: number}}
 */
export const netImpact = ({
  monthlyRevenuePerStore,
  averageTransactionValue,
  averageTransactionValueIncrease,
  yoyoShareOfCheckout,
  transactionFrequencyIncrease,
  numberOfStores,
  percentageOfLoyalCustomersWhoRedeemRewards,
  valueOfRewards,
  currency,
}) => {
  const store =
    revenueUplift({
      monthlyRevenuePerStore,
      averageTransactionValue,
      averageTransactionValueIncrease,
      yoyoShareOfCheckout,
      transactionFrequencyIncrease,
      numberOfStores,
    }).store -
    estimatedCostOfProgramme({
      averageTransactionValue,
      monthlyRevenuePerStore,
      yoyoShareOfCheckout,
      averageTransactionValueIncrease,
      transactionFrequencyIncrease,
      numberOfStores,
      percentageOfLoyalCustomersWhoRedeemRewards,
      valueOfRewards,
      currency,
    }).store;

  const total = store * numberOfStores;

  return { total, store };
};

/**
 * @param {number} monthlyRevenuePerStore
 * @param {number} averageTransactionValue
 * @param {number} averageTransactionValueIncrease
 * @param {number} yoyoShareOfCheckout
 * @param {number} transactionFrequencyIncrease
 * @param {number} numberOfStores
 * @param {number} percentageOfLoyalCustomersWhoRedeemRewards
 * @param {number} valueOfRewards
 * @param {string} currency
 * @returns {{total: number, store: number}}
 */
export const returnOnInvestment = ({
  monthlyRevenuePerStore,
  averageTransactionValue,
  averageTransactionValueIncrease,
  yoyoShareOfCheckout,
  transactionFrequencyIncrease,
  numberOfStores,
  percentageOfLoyalCustomersWhoRedeemRewards,
  valueOfRewards,
  currency,
}) => {
  const store =
    netImpact({
      monthlyRevenuePerStore,
      averageTransactionValue,
      averageTransactionValueIncrease,
      yoyoShareOfCheckout,
      transactionFrequencyIncrease,
      numberOfStores,
      percentageOfLoyalCustomersWhoRedeemRewards,
      valueOfRewards,
      currency,
    }).store /
    estimatedCostOfProgramme({
      averageTransactionValue,
      monthlyRevenuePerStore,
      yoyoShareOfCheckout,
      averageTransactionValueIncrease,
      transactionFrequencyIncrease,
      numberOfStores,
      percentageOfLoyalCustomersWhoRedeemRewards,
      valueOfRewards,
      currency,
    }).store;

  const total = store * 100;
  return { total, store };
};
