/**
 * @param percent
 * @returns {number}
 */

export const percentToDecimal = (percent) => {
  return percent / 100
}
/**
 * @param {number} monthlyRevenuePerStore
 * @param {number} averageTransactionValue
 * @returns {number}
 */
export const transactionsPerMonth = ({ monthlyRevenuePerStore, averageTransactionValue }) => {
  return monthlyRevenuePerStore / averageTransactionValue
}
/**
 * @param {number} averageTransactionValue
 * @param {number}averageTransactionValueIncrease
 * @returns {number}
 */
export const upliftTransactionValue = ({ averageTransactionValue, averageTransactionValueIncrease }) => {
  return averageTransactionValue * percentToDecimal(averageTransactionValueIncrease)
}

/**
 * @param {number} monthlyRevenuePerStore
 * @param {number} averageTransactionValue
 * @param {number} transactionFrequencyIncrease
 * @returns {number}
 */
export const upliftTransactionFrequency = ({
  monthlyRevenuePerStore,
  averageTransactionValue,
  transactionFrequencyIncrease
}) => {
  return transactionsPerMonth({ monthlyRevenuePerStore, averageTransactionValue }) * percentToDecimal(transactionFrequencyIncrease)
}

/**
 * @param {number} monthlyRevenuePerStore
 * @param {number} averageTransactionValue
 * @param {number} transactionFrequencyIncrease
 * @param {number} yoyoShareOfCheckout
 * @returns {number}
 */
export const shareOfCheckout = ({
  monthlyRevenuePerStore,
  averageTransactionValue,
  transactionFrequencyIncrease,
  yoyoShareOfCheckout
}) => {
  return (
    transactionsPerMonth({ monthlyRevenuePerStore, averageTransactionValue }) +
    upliftTransactionFrequency({ monthlyRevenuePerStore, averageTransactionValue, transactionFrequencyIncrease })
  ) * percentToDecimal(yoyoShareOfCheckout)
}
